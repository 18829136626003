// src/templates/article-detail.js
//import React from "react"
import React, { useEffect } from "react"
import Prism from "prismjs"
import "prismjs/components/prism-python"
import "prismjs/components/prism-javascript"
import "prismjs/components/prism-ruby"
import "prismjs/components/prism-markdown"
import "prismjs/components/prism-json"
import "prismjs/components/prism-yaml"
import "prismjs/components/prism-bash"
import "prismjs/components/prism-sql"
import "prismjs/components/prism-cshtml"
import "prismjs/themes/prism-twilight.css"

import { graphql } from "gatsby"
import Seo from "../components/seo"
import Layout from "../components/layout"
import Aside from "../components/aside"
import { StaticImage } from "gatsby-plugin-image"

const ArticleDetail = ({ data }) => {
  const article = data.nanoCms.article
  const siteTitle = data.site.siteMetadata?.title || `Title`

  // prismjsの呼び出し
  useEffect(() => {
    // 全ての <code> タグを取得
    document.querySelectorAll("pre > code:not([class])").forEach(block => {
      // classが未設定のものに対して、language-pythonを設定
      block.className = "language-python"
    })
    Prism.highlightAll()
  }, [])

  return (
    <Layout location="ja" title={siteTitle}>
      <main>
        <div className="cntWrapper">
          <ol className="breadcrumb">
            <li>
              <a href="/">TOP</a>
            </li>
            <li className="breadcrumb-category">
              {article.category || `pages`}
            </li>
            <li>{article.title}</li>
          </ol>
          <div className="cntWrapperIndex">
            <div className="mainnav">
              <article className="post">
                <div className="articleBar">
                  <div className="categoryBar">
                    {article.category || `pages`}
                  </div>
                  <div className="postDate">{article.data}</div>
                </div>
                <h1>{article.title}</h1>
                <div className="subTitle">{article.description}</div>
                {article.ogpImage ? (
                  <img
                    src={article.ogpImage}
                    alt="ogp"
                    className="featuredImage"
                  />
                ) : (
                  <StaticImage
                    src="../images/nanocms.png"
                    alt="ogp"
                    className="featuredImage"
                  />
                )}
                <div dangerouslySetInnerHTML={{ __html: article.content }} />
                <hr />
              </article>
            </div>
            <Aside />
          </div>
        </div>
      </main>
    </Layout>
  )
}

export const Head = ({ data }) => {
  const article = data.nanoCms.article
  const siteUrl = data.site.siteMetadata?.siteUrl
  return (
    <Seo
      title={article.title}
      description={article.description}
      cover={article.ogpImage || `${siteUrl}/nanocms.png`}
    />
  )
}

export default ArticleDetail

export const query = graphql`
  query ($timestamp: Int!) {
    nanoCms {
      article(timestamp: $timestamp) {
        title
        description
        ogpImage
        category
        content
        updatedAt
        timestamp
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
