import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PopularArticle from "./popular-article"

const Aside = () => {
  const { nanoCms } = useStaticQuery(graphql`
    query {
      nanoCms {
        articles(isPublished: true, limit: 5) {
          edges {
            node {
              title
              description
              category
              ogpImage
              url
              content
              updatedAt
              timestamp
            }
          }
        }
      }
    }
  `)
  var articles = nanoCms.articles
  articles = articles.edges.map(edge => edge.node)

  return (
    <aside className="sidenav">
      <div className="ad">
        <a
          href="https://px.a8.net/svt/ejp?a8mat=3TAQYK+BFELC2+50+2HNVPD"
          rel="nofollow"
        >
          <img
            border="0"
            width="300"
            height="250"
            alt=""
            src="https://www21.a8.net/svt/bgt?aid=230608892691&wid=003&eno=01&mid=s00000000018015060000&mc=1"
          />
        </a>
        <img
          border="0"
          width="1"
          height="1"
          src="https://www10.a8.net/0.gif?a8mat=3TAQYK+BFELC2+50+2HNVPD"
          alt=""
        />
      </div>
      <div className="popular-article">
        <h2 className="aside-h2">新着記事</h2>
        {articles.map((article, index) => (
          <PopularArticle key={index} article={article} />
        ))}
      </div>
    </aside>
  )
}

export default Aside
