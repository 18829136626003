import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const PopularArticle = ({ article }) => {
  return (
    <article className="articleItemAside">
      <div className="articleLeft">
        <Link to={`/${article.url}/`}>
          {article.ogpImage ? (
            <img
              src={article.ogpImage}
              alt={article.title}
              className="aside-content-left-picture"
            />
          ) : (
            <StaticImage
              src="../images/nanocms.png"
              alt={article.title}
              className="aside-content-left-picture"
            />
          )}
        </Link>
      </div>
      <div className="articleRight">
        <Link to={`/${article.url}/`}>
          <p>{article.title}</p>
        </Link>
      </div>
    </article>
  )
}

export default PopularArticle
